.modal {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 14px;
  }
  
  .modal-footer {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
  }