.dropbtn {
  background-color: transparent;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 5px;
  right: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 150px;
  top: 0;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f3efe9
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
  color: #031924;
}

.icon {
  height: 24px;
  width: 24px;
}